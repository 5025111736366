export enum D365Entities {
  CustomerV3 = 'CustomersV3', // client
  SalesOrderHeadersV2 = 'SalesOrderHeadersV2',
  SalesOrderLines = 'SalesOrderLines',
  EmployeeV2 = 'EmployeesV2',
  GPLReferencingGroupRels = 'GPLReferencingGroupRels',
  GPLReferencingTables = 'GPLReferencingTables',
  GPLItemsPerGroups = 'GPLItemsPerGroups',
  ProductsV2 = 'ProductsV2',
  WarehousesOnHandV2 = 'WarehousesOnHandV2', // Entrepôts
  SystemUsers = 'SystemUsers',
  ProductSpecificOrderSettingsV2 = 'ProductSpecificOrderSettingsV2',
  SPLDirPartyEcoResCategories = 'SPLDirPartyEcoResCategories',
  SPLHierarchyCategoryReferencings = 'SPLHierarchyCategoryReferencings',
  SPLResProductCategories = 'SPLResProductCategories',
  SPLInventParameters = 'SPLInventParameters',
  DeliveryModesV2 = 'DeliveryModesV2',
  SPLSalesOrderHeadersV2 = 'SPLSalesOrderHeadersV2',
  SPLLogiLabHistoryErrors = 'SPLLogiLabHistoryErrors',
  ReleasedProductVariantsV2 = 'ReleasedProductVariantsV2',
  SPLSalesOrderHistories = 'SPLSalesOrderHistories',
}
export enum D365SPLSalesOrderHeaderV2BoundAction {
  GetLastPackingSlipId = "GetLastPackingSlipId",
  SetLineInventSerialId = "SetLineInventSerialId",
  ShipmentValidation = "ShipmentValidation",
  PickingAssignment = "PickingAssignment",
  UpdateLineInventSerialId = "UpdateLineInventSerialId",
  CancelOrderLinesQuantity = "CancelOrderLinesQuantity",
  GeneratePackingSlip = "GeneratePackingSlip",
  prodTableMarkup = "prodTableMarkup",
  SendLineToTL = "SendLineToTL",
  ConfirmPickingList = "ConfirmPickingList",
  ConfirmSalesOrder = "ConfirmSalesOrder",
  DeliverSalesOrder = "DeliverSalesOrder",
  cancelSalesOrder = "cancelSalesOrder",
  GetLastPackingSlipToBase64 = "GetLastPackingSlipToBase64",
  CustCatalog = "CustCatalog",
  BreakOrderLink = "BreakOrderLink"
}
