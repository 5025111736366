import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { type Observable, map } from 'rxjs';
import { LoggerService } from '@services/log/logger.service';

import { HttpMethod } from '@app/core/models/dto/gateway-midleware/HttpMethod.enum';
import { HttpStatusCode } from '@app/core/models/dto/gateway-midleware/HttpStatusCode.enum';
import { type D365GatewayRequest } from '@app/core/models/dto/gateway-midleware/d365-gateway-request';
import { type D365GatewayResponse } from '@app/core/models/dto/gateway-midleware/d365-gateway-response';
import { environment } from '@environments/environment';
import { InterceptorLabels } from '@app/core/models/enums/interceptors-label.enum';
import { TOAST_STATE } from '@app/core/models/viewModels/ui-components/alerts/alert';
import { AlertService } from '../ui-components/alerts/alert.service';


export const GATEWAY_TARGET = 'gateway-target';
export const TARGET = 'target';
export const D365GW = 'd365gw';
@Injectable({
  providedIn: 'root'
})
export class D365GatewayService {
  private readonly _baseUrl: string = environment.gateway.url;
  private readonly _mailEP: string = environment.gateway.segments.mail;
  private readonly _requestEP: string = environment.gateway.segments.request;
  private readonly _batchEP: string = `${this._requestEP}/${environment.gateway.segments.sendBatch}`;
  private readonly HttpClient: HttpClient = inject(HttpClient);
  private readonly logger: LoggerService = inject(LoggerService);
  alertService: AlertService = inject(AlertService);

  public prepareGatewayRequest(query: string, method: HttpMethod, body: unknown = undefined): D365GatewayRequest {
    const request = {
      Method: HttpMethod[method],
      Query: query,
      Body: body
    };
    return request;
  }
  private sendtoGateway<T>(endpoint: string, request: D365GatewayRequest): Observable<D365GatewayResponse<T>> {
    const url = this._baseUrl+endpoint;
    const stringifiedRequest = JSON.stringify(request);
    return this.HttpClient.post<D365GatewayResponse<T>>(url, stringifiedRequest, { headers: this.getHeaders([InterceptorLabels.CRUD_REQUESTS]) }).pipe(
      map((gatewayResponse: D365GatewayResponse<T>) => this.CheckAndReturnGatewayResult(gatewayResponse))
    );
  }
  public sendMail<T>(request: D365GatewayRequest): Observable<D365GatewayResponse<T>> {
    return this.sendtoGateway<T>(this._mailEP, request);
  }
  public sendGatewayRequest<T>(request: D365GatewayRequest): Observable<D365GatewayResponse<T>> {
    return this.sendtoGateway<T>(this._requestEP, request);
  }
  public sendBatch(request: D365GatewayRequest): Observable<D365GatewayResponse<boolean>>{
    return  this.sendtoGateway<boolean>(this._batchEP,request);
  }
  private CheckAndReturnGatewayResult<T>(gatewayResponse: D365GatewayResponse<T>): D365GatewayResponse<T> {
    let message = '';
    if (gatewayResponse === undefined) {
      this.logger.error('Error : No response');
      this.alertService.showAlert(TOAST_STATE.warning, true, 'No Response', 'Error : No response');
      return {} as unknown as D365GatewayResponse<T>;
    }
    switch (gatewayResponse.statusCode) {
      case HttpStatusCode.OK:
        if (gatewayResponse.method === HttpMethod.POST) {
          message = this.parseMessage(gatewayResponse.response as string);
          return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.success, 'POST OK', message);
        }
        return gatewayResponse;

      case HttpStatusCode.Created:
        return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.success, 'Creation OK : ');

      case HttpStatusCode.NoContent:
        return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.info, 'No content : ');


      case HttpStatusCode.NotFound:
        message = this.parseMessage(gatewayResponse.response as string);
        this.logger.info('Error : Not found');

        this.alertService.showAlert(TOAST_STATE.info, true, 'Error : Not found', `\n Error ${gatewayResponse.statusCode}\n \n ${message}`);
        return this.getdefaultResponseValue<T>();

      case HttpStatusCode.Unauthorized:
        message = this.parseMessage(gatewayResponse.response as string);
        this.logger.error('Error : Gateway unauthorized');
        this.alertService.showAlert(TOAST_STATE.warning, true, 'Error : Gateway unauthorized', `Gateway : UnAuthorized Reload your App or try again later. \n Error ${gatewayResponse.statusCode}\n \n ${message}`);
        document.location.reload();
        throw new Error(`Error ${gatewayResponse.statusCode}`);
      // return this.getdefaultResponseValue<T>();

      case HttpStatusCode.BadRequest:
        message = this.parseMessage(gatewayResponse.response as string);

        this.logger.error('Error : Gateway Bad Request');
        this.alertService.showAlert(TOAST_STATE.warning, true, 'Error : Gateway Bad Request', `Gateway : Server not found or bad request.  \n Contact your system administrator \n Error ${gatewayResponse.statusCode}\n \n ${message}`);
        throw new Error(`Error ${gatewayResponse.statusCode}`);
      // return this.getdefaultResponseValue<T>();

      case HttpStatusCode.InternalServerError:
        message = this.parseMessage(gatewayResponse.response as string);

        this.logger.error('Error : Internal server error');
        this.alertService.showAlert(TOAST_STATE.danger, true, 'Error : Internal server error', `Reload your App or try again later. \n Error ${gatewayResponse.statusCode}\n \n ${message}`);
        document.location.reload();
        throw new Error(`Error ${gatewayResponse.statusCode}`);

      default:
        this.logger.error(`Error ${gatewayResponse.statusCode}`);
        message = this.parseMessage(gatewayResponse.response as string);

        this.alertService.showAlert(TOAST_STATE.warning, true, `Error ${gatewayResponse.statusCode}`, `Reload your App or try again later. \n Error ${gatewayResponse.statusCode}\n \n ${message}`);
        throw new Error(`Error ${gatewayResponse.statusCode}`);
    }
  }
  public getdefaultResponseValue<T>(): D365GatewayResponse<T> {
    return {} as unknown as D365GatewayResponse<T>;
  }
  private parseMessage(message: string): string {
    console.log(message);
    let errorMessage = '';
    try {
      const parsedMessage = JSON.parse(message);
      errorMessage = parsedMessage.error.innererror.message;
      return errorMessage;
    } catch (error) {
      this.logger.displayObjectError(error as Error, 'Erreur de parsing JSON :');
      return errorMessage;
    }
  }
  private getHeaders(ressource: InterceptorLabels[] | undefined = undefined): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Target', 'application/json');
    if (ressource !== undefined) {
      // console.log("ressource isn't undefined");
      httpHeaders = httpHeaders.set(GATEWAY_TARGET, ressource);
    }
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return httpHeaders;
  }
  private traceAndReturnResponse<T>(gatewayResponse: D365GatewayResponse<T>, title: string, toastLevel: string, msg = ''): D365GatewayResponse<T> {
    //this.logger.displayObjectDebug(gatewayResponse, title);
    this.alertService.showAlert(toastLevel, false, title, msg);
    return gatewayResponse;
  }

}
