import * as CryptoJS from 'crypto-js';

export function parseStringToEnum<T extends object>(enumType: T, value: string): T[keyof T] | undefined {
  const enumValues = Object.values(enumType) as string[];
  if (enumValues.includes(value)) {
      return value as T[keyof T];
  }
  return undefined;
}
export function parseValuetoArray<T>(value: T[] | T): T[] {
  return Array.isArray(value) ? value : [value];
}

export function preventZero(value: number): number{
  return value > 0 ? value : 1;
}



// Type pour le dictionnaire de correspondances
export type Mappings = { [key: string]: string };


// Fonction pour décoder une chaîne avec une clé
export function decodeString(input: string, key: string): string {
  try {
      // Extraire salt, iv, et texte chiffré
      const salt = CryptoJS.enc.Hex.parse(input.substr(0, 32));
      const iv = CryptoJS.enc.Hex.parse(input.substr(32, 32));
      const encrypted = input.substring(64);

      // Dériver la clé avec PBKDF2
      const key256Bits = CryptoJS.PBKDF2(key, salt, {
          keySize: 256/32,
          iterations: 1000
      });

      // Décrypter
      const decrypted = CryptoJS.AES.decrypt(encrypted, key256Bits, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
      console.error('Erreur de décryptage:', e);
      return '';
  }
}

// Fonction pour créer le mappage à partir des chaînes décodées
export function createMappings(input: string, output: string): Mappings {
  const mappings: Mappings = {};
  for (let i = 0; i < input.length && i < output.length; i++) {
      mappings[input[i]] = output[i];
  }
  return mappings;
}


/**
 * Transforme une chaîne d'entrée en utilisant le dictionnaire de correspondances fourni.
 * @param input La chaîne d'entrée à transformer.
 * @param mappings Le dictionnaire de correspondances sous forme d'objet JSON.
 * @returns La chaîne transformée.
 */
export function applyMapping(input: string, mappings: Mappings): string {
  return input.split('').map(char => mappings[char] || char).join('');
}
