import { StringValidator } from '@app/shared/utils/validators/string.validator';
import { Injectable, inject } from '@angular/core';
import { type ODataArrayResult } from '@app/core/models/dto/odata-result';
import { LoggerService } from '@app/core/services/log/logger.service';
import { FoOdataService } from '@services/finops/fo-odata.service';
@Injectable({
  providedIn: 'root'
})
export abstract  class FoBaseClientApiService<T> {
  protected foODataService = inject(FoOdataService);
  protected logger = inject(LoggerService);
  protected readonly defaultOdataResult = this.getDefaultOdataArrayResultValue();

  /**
   * Returns an ODataArrayResult object with a default value property of an empty array.
   * The '@odata.context' property is an empty string.
   *
   * @returns - An ODataArrayResult object with default values.
   */
  getDefaultOdataArrayResultValue (): ODataArrayResult<T> {
    const r: ODataArrayResult<T> = {
      '@odata.context': '',
      value: []
    };
    return r;
  }

  /**
    * Abstract method to get the entity name.
    * Every derived class must implement this method to provide the specific entity.
   */
  protected abstract getMainEntityName(): string;

  /**
   * Generates a URL for the specified entity with optional parameters.
   *
   * @param entityName - The name of the D365 entity.
   * @param params - An object representing key-value pairs for query parameters.
   * @param crossCompany - Optional boolean indicating if the cross-company parameter should be included (default is true).
   * @returns - The generated URL.
   */
  protected getEntityURL( params?: { [key: string]: string }, crossCompany: boolean = true): string {
    const entityName = this.getMainEntityName();
    const baseEntityURL = `/data/${entityName}`;
    let url = baseEntityURL;

    if (params && Object.values(params).every(param => !StringValidator.isNullOrEmptyString(param))) {
      const queryParams = Object.entries(params)
        .map(([key, value]) => `${key}='${value}'`)
        .join(',');
      url += `(${queryParams})`;
    }

    if (crossCompany) {
      url += `?cross-company=true`;
    }

    return url;
  }
}

