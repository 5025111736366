import { Injectable, inject } from '@angular/core';
import { map, type Observable } from 'rxjs';
import buildQuery from 'odata-query';
import { LoggerService } from '@services/log/logger.service';
import { D365GatewayService } from '@services/finops/D365-gateway.service';
import { HttpMethod } from '@app/core/models/dto/gateway-midleware/HttpMethod.enum';
import { type ODataArrayResult } from '@app/core/models/dto/odata-result';
import { D365GatewayResponse } from '@app/core/models/dto/gateway-midleware/d365-gateway-response';
import { KeyValue } from '@angular/common';
import { environment } from '@environments/environment';

// Cette classe interagit avec l'API OData en passant par le gateway D365.
@Injectable({
  providedIn: 'root'
})
export class FoOdataService {
  private readonly d365GatewayService: D365GatewayService = inject(D365GatewayService);
  private readonly logger: LoggerService = inject(LoggerService);
  private readonly batchID : string = environment.fo.batchId;
  //#region Base methodes
  private GetEntities<T>(endpointQuery: string): Observable<ODataArrayResult<T>> {
// this.logger.debug(endpointQuery);

    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.GET);
      // this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService GetEntities : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<ODataArrayResult<T>>(gatewayRequest).pipe(
        map((gatewayResponse: D365GatewayResponse<ODataArrayResult<T>>) => gatewayResponse.response),
      );
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }



  private postEntity<T>(endpointQuery: string, body: unknown=undefined): Observable<D365GatewayResponse<T>> {
// this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.POST, body);
      // this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService postEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest);
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }


  private postBatch( body: {value:string,batchId:string}):Observable<D365GatewayResponse<boolean>>{
    // this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(`$Batch::${body.batchId}`,HttpMethod.POST,body);
       return this.d365GatewayService.sendBatch(gatewayRequest);
    }catch (error){
      console.error('Error making request:', error);
      throw error;
    }
  }

  private patchEntity<T>(endpointQuery: string, body: unknown=undefined): Observable<D365GatewayResponse<T>> {
// this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.PATCH, body);
      // this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService patchEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest);
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }

  private deleteEntity<T>(endpointQuery: string): Observable<D365GatewayResponse<T>> {
// this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.DELETE);
      // this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService deleteEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest);
    } catch (error) { console.error('Error making request:', error); throw error; }}

  //#endregion

//#region GET

  /**
   * Prepares a query string based on the provided parameters.
   *
   * @param {string[]} selectFields - An array of fields to select from the query.
   * @param {unknown} filterParams - The filter parameters to apply to the query.
   * @param {string[]} [orderBy=[]] - An array of fields to order the query by.
   * @param {string[]} [expand=[]] - An array of fields to expand in the query.
   * @param {number} [top] - The maximum number of records to retrieve.
   * @return {string} The prepared query string.
   */
  private prepareQuery (selectFields: string[], filterParams: unknown, orderBy: string[] = [], expand: string[] = [], top?: number): string {
    const query: Record<string, unknown> = {};

    if (selectFields.length > 0) {
      query['select'] = selectFields;
    }

    if (filterParams !== undefined) {
      // query.filter = filterParams as string;
      // query.filter = filterParams as string;
      query['filter'] = filterParams;
    }

    if (orderBy !== undefined && orderBy.length > 0) {
      query['orderby'] = orderBy.join(',');
    }

    if (expand !== undefined && expand.length > 0) {
      query['expand'] = expand.join(',');
    }

    if (top !== undefined && top !== null) {
      query['top'] = top;

    }

    return buildQuery(query);
  }
  /**
   * Retrieves data from the server based on the specified parameters.
   * @see https://docs.microsoft.com/en-us/dynamics365/fin-ops-core/dev-itpro/data-entities/odata
   * @param entityName - The name of the entity to retrieve data for.
   * @param selectFields - An array of fields to select from the entity.
   * @param filterParams - The filter parameters to apply to the data. Use odat-query to build the filter.
   * @see https://github.com/techniq/odata-query
   * @param orderBy - An array of fields to order the data by.
   * @param expand - An array of fields to expand in the data.
   * @param crossCompany - Indicates whether to retrieve data from multiple companies.
   * @param top - The maximum number of records to retrieve.
   * @returns An Observable that emits the retrieved data.
   */
  public getSomeWithFilterParams<T>(entityName: string, selectFields: string[] = [], filterParams: unknown = undefined, orderBy: string[] = [], expand: string[] = [], crossCompany: boolean = false, top: number | undefined = undefined): Observable<ODataArrayResult<T>> {
    const queryString = this.prepareQuery(selectFields, filterParams, orderBy, expand, top);

    let endpointQuery = '';
    endpointQuery = `${entityName}${queryString}${crossCompany ? '&cross-company=true' : ''}`;

    return this.GetEntities<T>(endpointQuery);

  }
  public getSomeWithFilterString<T>(entityName: string, selectFields: string[] = [], filterString: string = '', orderBy: string[] = [], expand: string[] = [], crossCompany: boolean = false, top: number | undefined = undefined): Observable<ODataArrayResult<T>> {
    const queryString = this.prepareQuery(selectFields,null, orderBy, expand, top);
    let endpointQuery = '';
    if (filterString !== '') {
      endpointQuery = `${entityName}${queryString}&$filter=${filterString}${crossCompany ? '&cross-company=true' : ''}`;
    }else{
      endpointQuery = `${entityName}${queryString}${crossCompany ? '&cross-company=true' : ''}`;
    }
    return this.GetEntities<T>(endpointQuery);
  }


//#endregion GET

//#region POST
public postObject<T>(entityName: string, crossCompany: boolean = false, body: unknown=undefined): Observable<D365GatewayResponse<T>> {
  const endpointQuery = `${entityName}?${crossCompany ? '&cross-company=true' : ''}`;
  this.logger.debug(endpointQuery);
  return this.postEntity<T>(endpointQuery, body);
}

public postBatchObject (batchPlainText:string):Observable<D365GatewayResponse<boolean>>{
  return this.postBatch( {value: batchPlainText, batchId:this.batchID});
}

public postToBoudAction<T>(entityName: string,keys: KeyValue<string,string> [] | undefined,BoundActionName : string, crossCompany: boolean = false,body: unknown=undefined ): Observable<D365GatewayResponse<T>> {
  let endpointQuery ="";
  if(keys && keys.length > 0){
    const selector = this.generateStringKeyPairValues(keys);
    endpointQuery = `${entityName}(${selector})/Microsoft.Dynamics.DataEntities.${BoundActionName}?${crossCompany ? '&cross-company=true' : ''}`;
  }else{
    endpointQuery = `${entityName}/Microsoft.Dynamics.DataEntities.${BoundActionName}?${crossCompany ? '&cross-company=true' : ''}`;
  }

  this.logger.debug(endpointQuery);
  if(body){
    return this.postEntity<T>(endpointQuery,body);
  }else{
    return this.postEntity<T>(endpointQuery);
  }
}

generateStringKeyPairValues(values: KeyValue<string,unknown> []): string {
  return values.map(({ key, value }) => `${String(key)}=${value}`).join(' , ');
}

public patchObject<T>(entityName: string, keys: KeyValue<string,unknown> [], body: unknown=undefined): Observable<D365GatewayResponse<T>> {
  const selector = this.generateStringKeyPairValues(keys);
  const endpointQuery = `${entityName}(${selector})?&cross-company=true`;
  this.logger.debug(endpointQuery);
  return this.patchEntity<T>(endpointQuery, body);
}

//#endregion POST


//#region PATCH
//#endregion PATCH

//#region DELETE
public deleteObject<T>(entityName: string,options: KeyValue<string,unknown> []): Observable<D365GatewayResponse<T>> {
  const params =this.generateStringKeyPairValues(options);
  const endpointQuery = `${entityName}(${params})?&cross-company=true`;
  this.logger.debug(endpointQuery);
  return this.deleteEntity<T>(endpointQuery);
}



//#endregion DELETE


}


// Ajoutez d'autres méthodes pour POST, PUT, DELETE, etc.
/**
 * Points Clés:
  Inclure des méthodes pour différentes opérations OData (GET, POST, etc.).
  Utiliser le token obtenu de TokenService pour l'authentification.
  Gérer la construction des URL et les requêtes OData.
 *
 * Points Clés à Noter:

  Sécurité: Comme mentionné précédemment, assurez-vous que les informations sensibles sont stockées et gérées en toute sécurité. > environnement
  Gestion des erreurs: La gestion robuste des erreurs est cruciale pour assurer la stabilité de l'application.
  Gestion des Tokens: Pensez à implémenter un mécanisme de rafraîchissement des tokens si nécessaire.

  Injection de Dépendances: Angular gère l'instanciation des services grâce à son système d'injection de dépendances. Assurez-vous que vos services sont correctement fournis dans le module approprié (généralement le AppModule ou un module de fonctionnalité).
  Observable: HttpClient en Angular retourne des Observable qui offrent plus de contrôle sur les requêtes HTTP, notamment pour la gestion des erreurs et des opérations asynchrones.
  Sécurité et Gestion des Tokens: Assurez-vous de gérer les tokens de manière sécurisée et envisagez d'implémenter une logique de rafraîchissement des tokens si nécessaire.

  Ces classes doivent être ajustées en fonction des spécificités de votre application Angular et de l'API D365 FO que vous utilisez.
 */
