import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertType$ = new BehaviorSubject<string>('info');
  showsAlert$ = new BehaviorSubject<boolean>(false);
  alertTitle$ = new BehaviorSubject<string>('Default title');
  alertDescription$ = new BehaviorSubject<string>('');
  isExpanded$ = new BehaviorSubject<boolean>(false);
  displayTime$ = new BehaviorSubject<number>(5000);
  private hideTimeout: number | null = null;

/**
 * Displays an alert with the specified type, title, and optional message for a specific duration.
 *
 * @param {string} type - The type of the alert (e.g., 'info', 'warning', 'error').
 * @param {boolean} isExpanded - A boolean indicating whether the alert should be expanded.
 * @param {string} title - The title of the alert.
 * @param {string} [message] - The optional message content of the alert.
 * @param {number} [displayTime=5000] - The duration in milliseconds for which the alert is displayed.
 * @return {void} This function does not return anything.
 */
  showAlert(type: string, isExpanded: boolean, title: string, message?: string, displayTime: number = 5000) {
    this.alertType$.next(type);
    this.alertTitle$.next(title);
    this.alertDescription$.next(message || '');
    this.showsAlert$.next(true);
    this.isExpanded$.next(false);
    this.displayTime$.next(displayTime);
    this.isExpanded$.next(isExpanded);

    if (this.hideTimeout !== null) {
      clearTimeout(this.hideTimeout);
    }

    this.hideTimeout = window.setTimeout(() => {
      this.showsAlert$.next(false);
    }, displayTime);
  }

  /**
   * Toggles the expanded state of the component and updates the visibility of the alert accordingly.
   *
   * @return {void} This function does not return anything.
   */
  toggleExpand() {
    const isExpanded = !this.isExpanded$.value;
    this.isExpanded$.next(isExpanded);

    if (this.hideTimeout !== null) {
      clearTimeout(this.hideTimeout);
    }

    const newDisplayTime = isExpanded ? 20000 : this.displayTime$.value;

    this.hideTimeout = window.setTimeout(() => {
      this.showsAlert$.next(false);
    }, newDisplayTime);
  }

  /**
   * Closes the alert by clearing the hideTimeout if it exists and setting showsAlert$ to false.
   *
   * @return {void} This function does not return anything.
   */
  closeAlert() {
    if (this.hideTimeout !== null) {
      clearTimeout(this.hideTimeout);
    }
    this.showsAlert$.next(false);
  }
}
