import { DestroyRef, Injectable, inject } from '@angular/core';
import { BehaviorSubject, of, type Observable } from 'rxjs';
import { UserRole } from '@app/core/models/enums/user-role.enum';
import { type Customer } from '@app/core/models/viewModels/customer';
import { environment } from '@environments/environment';
import { RequestableFoSplInventParametersDto } from '@app/core/models/dto/fo/fo.splInventParameter.dto';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FoInventParameterService } from '../finops/client-api/fo-invent-parameter.service';
import { LoggerService } from '@services/log/logger.service';
import { KeycloakProfile } from 'keycloak-js';
import { User } from '@app/core/models/viewModels/user';
import { applyMapping, createMappings, decodeString } from '@app/shared/utils/parsers/parser';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {


  private readonly logger: LoggerService = inject(LoggerService);
  private readonly pageNameSubject = new BehaviorSubject<string>('');

  private readonly editmode = new BehaviorSubject<boolean>(false);
  private readonly warehouseParamsSubject = new BehaviorSubject<RequestableFoSplInventParametersDto>({} as RequestableFoSplInventParametersDto);

  private readonly displayOrderErrorWarning = new BehaviorSubject<boolean>(true);
  private readonly userProfileSubject = new BehaviorSubject<User>({
    person: {
      firstName: '',
      lastName: '',
      ref: '',
      contact: {
        mail: '',
        phone: '',
      },
    },
    registerNumber: '',
    defaultDataAreaId: '',
    role: UserRole.NONE,
    language: '',
    customerProfiles: [],
    alias: '',
    logisticEmployeeId: 0,
  } as User);

  private readonly orderFilterStatus = new BehaviorSubject<string>('');

  destroyRef: DestroyRef = inject(DestroyRef);

  setOrderFilterStatus(value : string){
    this.orderFilterStatus.next(value);
  }
  getOrderFilterStatus(){
    return this.orderFilterStatus.value;
  }

  isNotCustomer() {
    return this.userProfileSubject.value.role !== UserRole.USER;
  }

  setPageName(pageName: string): void {
    this.pageNameSubject.next(pageName);
  }
  getPageNameObs(): Observable<string> {
    return this.pageNameSubject.asObservable();
  }
  getPageName(): string {
    return this.pageNameSubject.value;
  }

  //#region User

  setUser(user: User): void {
    this.userProfileSubject.next(user);
  }
  getUser(): User {
    return this.userProfileSubject.value;
  }

///////////////
  setRegisterNumber(registerNumber: string) {
    this.userProfileSubject.value.registerNumber = registerNumber;
    this.userProfileSubject.next(this.userProfileSubject.value);

  }
  setUserPersonRef(ref: string) {
    this.userProfileSubject.value.person.ref = ref;
    this.userProfileSubject.next(this.userProfileSubject.value);

  }
  setUserDefaultDataAreaId(defaultDataAreaId: string) {
    this.userProfileSubject.value.defaultDataAreaId = defaultDataAreaId;
    this.userProfileSubject.next(this.userProfileSubject.value);

  }

  setUserCustomerProfiles(customerProfiles: Customer[]): void {
    this.userProfileSubject.value.customerProfiles = customerProfiles;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
//////////////////

  /**
   * @Used for combine Catalogs or orderHeaders Obs
   * @returns
   */
  getUserCustomerProfilesObs(): Observable<Customer[]> {
    if (this.userProfileSubject.value.customerProfiles)
      return of(this.userProfileSubject.value.customerProfiles);
    return of([] as Customer[]);

  }
  /**
   *  @Used for retrieve Customers of the user
   * @default returns an empty array
   * @returns Customer[] of the user (1 or more)
   */
  getUserCustomersProfiles(): Customer[] {
    if (this.userProfileSubject && this.userProfileSubject.value && this.userProfileSubject.value.customerProfiles)
      return this.userProfileSubject.value.customerProfiles;
    return [];
  }
  getFirstCustomerProfile(): Observable<Customer> {
    // return of(this.customerProfilesSubject.value[0]);
    if (this.userProfileSubject && this.userProfileSubject.value && this.userProfileSubject.value.customerProfiles)
      return of(this.userProfileSubject.value.customerProfiles[0]);
    return of({} as Customer);
  }
  setUserRoles(userRole: string[]): void {
    this.userProfileSubject.value.role = this.getTopUserRole(userRole);
    this.userProfileSubject.next(this.userProfileSubject.value);

  }
  getUserRole(): string {
    return this.userProfileSubject.value.role;
  }


  private getTopUserRole(userRole: string[]): UserRole {
    let highestRole: UserRole = UserRole.NONE;

    userRole.forEach((role: string) => {
      switch (role) {
        case environment.keycloak.roles.admin:
          highestRole = UserRole.ADMIN;
          break;
        case environment.keycloak.roles.logistician:
          if (highestRole !== UserRole.ADMIN) {
            highestRole = UserRole.LOGISTIC;
          }
          break;
        case environment.keycloak.roles.user:
          if (highestRole === UserRole.NONE) {
            highestRole = UserRole.USER;
          }
          break;
        default:
          break;
      }
    });

    return highestRole;
  }

  setUserLang(userLang: string): void {
    this.userProfileSubject.value.language = userLang;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }

  getUserLang$(): Observable<string> {
    return of(this.userProfileSubject.value.language);
  }
  getUserLang(): string {
    return this.userProfileSubject.value.language;
  }
  setUserProfilesFromKc(kcProfile: KeycloakProfile) {
    if (kcProfile != null) {
      if (kcProfile.firstName != null) this.userProfileSubject.value.person.firstName = kcProfile.firstName;
      if (kcProfile.lastName != null) this.userProfileSubject.value.person.lastName = kcProfile.lastName;
      if (kcProfile.email != null) this.userProfileSubject.value.person.contact.mail = kcProfile.email;
      // check if it really need or delete here & fields in interface & binding in the mappers
      if (kcProfile.username != null) this.userProfileSubject.value.alias = kcProfile.username;

      this.userProfileSubject.next(this.userProfileSubject.value);
    }
  }
  setUserLogisticId(logisticEmployeeId: number) {
    this.userProfileSubject.value.logisticEmployeeId = logisticEmployeeId;
    this.userProfileSubject.next(this.userProfileSubject.value);
  }
  getUserLogisticId(): number {
    return this.userProfileSubject.value.logisticEmployeeId;
  }

  resetUserAndCustomerInfo() {
    this.userProfileSubject.next({} as User);
  }
  //#endregion User




  getEditMode(): Observable<boolean> {
    return this.editmode.asObservable();
  }

  isOnEdtion(): boolean {
    return this.editmode.value;
  }

  setEditMode(editmode: boolean): void {
    this.editmode.next(editmode);
  }

  setWarehouseParams(value: RequestableFoSplInventParametersDto) {
    this.warehouseParamsSubject.next(value);
  }
  getWareHouseParams(): RequestableFoSplInventParametersDto {
    return this.warehouseParamsSubject.getValue();
  }

  private readonly foInventParameterService: FoInventParameterService = inject(
    FoInventParameterService
  );

  public initWareHouseParams() {
    if (this.isWarehouseParamsEmpty()) {
      const wareHouseParams$ =
        this.foInventParameterService.getSomeInventParamtersByDataAreaId(
          environment.fo.supplierCompany
        );
      wareHouseParams$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((params) => {
          this.setWarehouseParams(params.value[0]);
          this.logger.displayObjectDebug(
            this.getWareHouseParams(),
            'wareHouseParams initWareHouseParams'
          );
        });
    }
  }

  private isWarehouseParamsEmpty(): boolean {
    const params = this.warehouseParamsSubject.getValue();
    return (
      !params ||
      !params.SPLInventLocationId ||
      !params.SPLInventSiteId ||
      !params.dataAreaId
    );
  }

  getPrnLink():string{
    const k = decodeString(environment.prn.s, environment.fo.domain);
    const v = decodeString(environment.prn.e, environment.fo.domain);
    const mappings = createMappings(k, v);
    const prnkey = applyMapping(this.getUser().person.contact.mail,mappings);
    return `${environment.prn.baseHref}${this.getUserLang()}${environment.prn.authSegemnt}${prnkey}`;
  }


  // public getFoOrderUrl(dataAreaId: string, orderNumber: string): string {
  //   return `https://${environment.fo.domain}/?cmp=${dataAreaId}&mi=SalesTable&SPLSalesId=${orderNumber}`;
  // }

}
